<template>
<div class="relative z-[99999]" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div :class="mainStore.showInstructorNotifications ? 'animate__fadeIn' : 'animate__fadeOut'" class="fixed inset-0 bg-black bg-opacity-40 animate__animated"></div>

    <div class="fixed inset-0 overflow-hidden animate__animated animate__faster" :class="mainStore.showInstructorNotifications ? 'animate__fadeInRight' : 'animate__fadeOutRight'">
        <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-lg pl-10 sm:pl-16">

                <div class="pointer-events-auto w-screen">
                    <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-3xl">
                        <div class="p-6">
                            <div class="flex items-start justify-between">
                                <h2 class="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">Notifications <button @click="showHelp = !showHelp">
                                        <font-awesome-icon :icon="showHelp ? 'fa-solid fa-circle-info' : 'fa-regular fa-circle-info'" /></button> </h2>
                                <div class="ml-3 flex h-7 items-center">
                                    <button @click="mainStore.showInstructorNotifications = false, showHelp = false, mainStore.notificationView = 'list'" type="button" class="relative rounded-md bg-white text-gray-400">
                                        <span class="absolute -inset-2.5"></span>
                                        <span class="sr-only">Close panel</span>
                                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="!showHelp" class="border-b border-gray-200">
                            <div class="px-6">
                                <!-- Tab component -->
                                <nav class="-mb-px flex space-x-6">
                                    <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
                                    <button @click="mainStore.notificationView = 'list', mainStore.currentNotificationFilter = 'all'" :class="mainStore.currentNotificationFilter == 'all' ? 'border-teal-400 text-teal-400' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'" class="whitespace-nowrap border-b-2 px-1 pb-2.5 text-sm font-medium">All</button>
                                    <button @click="mainStore.notificationView = 'list', mainStore.currentNotificationFilter = 'unread'" :class="mainStore.currentNotificationFilter == 'unread' ? 'border-teal-400 text-teal-400' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'" class="hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-2.5 text-sm font-medium">Unread</button>
                                    <button @click="mainStore.notificationView = 'list', mainStore.currentNotificationFilter = 'refreps'" :class="mainStore.currentNotificationFilter == 'refreps' ? 'border-teal-400 text-teal-400' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'" class="hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-2.5 text-sm font-medium">RefReps</button>
                                </nav>
                            </div>
                        </div>
                        <div>

                            <button v-if="mainStore.currentNotificationFilter == 'unread' && !showHelp && notifications.length != 0" @click="markAllRead()" class="inline-flex justify-end text-xs text-teal-400 font-bold w-full pr-4 pt-4 animate__animated animate__fadeInRight">Mark All Read</button>

                            <Transition v-if="!showHelp" name="slide-fade" mode="out-in">
                                <div v-if="mainStore.notificationView == 'list'" class="dark:bg-gray-800">
                                    <p v-if="notifications.filter(n => n.category == 'today').length != 0" tabindex="0" class="focus:outline-none text-xs leading-3 text-gray-500 p-3">Today</p>
                                    <ul role="list" class="divide-y divide-gray-100 w-full">

                                        <li v-if="notifications.filter(n => n.category == 'today').length != 0" v-for="(notification, index) in notifications.filter(n => n.category == 'today')" @click="selectNotification(notification)" class="cursor-pointer relative py-5 hover:bg-gray-50 ">
                                            <div class="px-4">
                                                <div class="mx-auto flex max-w-4xl justify-between gap-x-6">
                                                    <div class="flex min-w-0 gap-x-4">
                                                        <img class="h-10 w-10 flex-none rounded-full object-cover bg-gray-50" :src="notification.author.profileImage ?? 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg'" alt="">
                                                        <div class="min-w-0 flex-auto">
                                                            <div class="flex items-center">
                                                                <p tabindex="0" class="focus:outline-none text-sm font-medium leading-4 text-gray-800 dark:text-gray-100">{{ notification.subject }}</p>
                                                            </div>
                                                            <p tabindex="0" class="focus:outline-none text-xs leading-4 text-gray-600 dark:text-gray-400 mt-1 line-clamp-2" v-html="notification.message"></p>
                                                            <p tabindex="0" class="focus:outline-none text-xs leading-4 text-gray-600 dark:text-gray-400 mt-1">{{ timeSince(notification.timestamp) }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="flex shrink-0 items-center gap-x-4">
                                                        <span v-if="!notification.views.map(v => v.id).includes(mainStore.user.id)" class="h-2 w-2 bg-teal-400 rounded-full"></span>
                                                        <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                    <hr v-if="notifications.filter(n => n.category == 'month').length != 0">
                                    <p v-if="notifications.filter(n => n.category == 'month').length != 0" tabindex="0" class="focus:outline-none text-xs leading-3 text-gray-500 dark:text-gray-400 p-3">This Month</p>
                                    <ul role="list" class="divide-y divide-gray-100 w-full">

                                        <li v-if="notifications.filter(n => n.category == 'month').length != 0" v-for="(notification, index) in notifications.filter(n => n.category == 'month')" @click="selectNotification(notification)" class="cursor-pointer relative py-5 hover:bg-gray-50 ">
                                            <div class="px-4">
                                                <div class="mx-auto flex max-w-4xl justify-between gap-x-6">
                                                    <div class="flex min-w-0 gap-x-4">
                                                        <img class="h-10 w-10 flex-none rounded-full object-cover bg-gray-50" :src="notification.author.profileImage ?? 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg'" alt="">
                                                        <div class="min-w-0 flex-auto">
                                                            <div class="flex items-center">
                                                                <p tabindex="0" class="focus:outline-none text-sm font-medium leading-4 text-gray-800 dark:text-gray-100">{{ notification.subject }}</p>
                                                            </div>
                                                            <p tabindex="0" class="focus:outline-none text-xs leading-4 text-gray-600 dark:text-gray-400 mt-1 line-clamp-2" v-html="notification.message"></p>
                                                            <p tabindex="0" class="focus:outline-none text-xs leading-4 text-gray-600 dark:text-gray-400 mt-1">{{ timeSince(notification.timestamp) }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="flex shrink-0 items-center gap-x-4">
                                                        <span v-if="!notification.views.map(v => v.id).includes(mainStore.user.id)" class="h-2 w-2 bg-teal-400 rounded-full"></span>
                                                        <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                    <hr v-if="notifications.filter(n => n.category == 'earlier').length != 0">
                                    <p v-if="notifications.filter(n => n.category == 'earlier').length != 0" tabindex="0" class="focus:outline-none text-xs leading-3 text-gray-500 dark:text-gray-400 p-3">Earlier</p>
                                    <ul role="list" class="divide-y divide-gray-100 w-full">

                                        <li v-if="notifications.filter(n => n.category == 'earlier').length != 0" v-for="(notification, index) in notifications.filter(n => n.category == 'earlier')" @click="selectNotification(notification)" class="cursor-pointer relative py-5 hover:bg-gray-50 ">
                                            <div class="px-4">
                                                <div class="mx-auto flex max-w-4xl justify-between gap-x-6">
                                                    <div class="flex min-w-0 gap-x-4">
                                                        <img class="h-10 w-10 flex-none rounded-full object-cover bg-gray-50" :src="notification.author.profileImage ?? 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg'" alt="">
                                                        <div class="min-w-0 flex-auto">
                                                            <div class="flex items-center">
                                                                <p tabindex="0" class="focus:outline-none text-sm font-medium leading-4 text-gray-800 dark:text-gray-100">{{ notification.subject }}</p>
                                                            </div>
                                                            <p tabindex="0" class="focus:outline-none text-xs leading-4 text-gray-600 dark:text-gray-400 mt-1 line-clamp-2" v-html="notification.message"></p>
                                                            <p tabindex="0" class="focus:outline-none text-xs leading-4 text-gray-600 dark:text-gray-400 mt-1">{{ timeSince(notification.timestamp) }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="flex shrink-0 items-center gap-x-4">
                                                        <span v-if="!notification.views.map(v => v.id).includes(mainStore.user.id)" class="h-2 w-2 bg-teal-400 rounded-full"></span>
                                                        <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                    <p v-if="notifications.length == 0" class="p-6 text-sm text-gray-400 w-full text-center">
                                        <font-awesome-icon icon="fa-regular fa-solid fa-bell-slash" class="mr-2" />There are no notifications.</p>
                                </div>
                                <div v-else>
                                    <div class="py-4 px-6">
                                        <button @click="mainStore.notificationView = 'list'" class="mb-4 text-sm">
                                            <font-awesome-icon icon="fa-regular fa-arrow-left" class="mr-1" /> Back</button>
                                        <div class="mx-auto flex max-w-4xl justify-between gap-x-6">
                                            <div class="flex min-w-0 gap-x-4">
                                                <!-- <img class="h-12 w-12 flex-none rounded-full object-cover bg-gray-50" :src="mainStore.selectedNot.sender_image ?? 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg'" alt=""> -->
                                                <div class="min-w-0 flex-auto">

                                                    <div class="flex items-center">
                                                        <p tabindex="0" class="focus:outline-none mb-1 text-[16px] font-bold leading-4 text-gray-800 dark:text-gray-100">{{ mainStore.selectedNotification.subject }}</p>
                                                    </div>

                                                    <p tabindex="0" class="focus:outline-none text-sm text-gray-600 mt-1" v-html="mainStore.selectedNotification.message"></p>
                                                    <p tabindex="0" class="focus:outline-none text-xs mb-1 leading-4 text-gray-400 mt-2">Posted {{ timeSince(mainStore.selectedNotification.timestamp) }} ago by {{ mainStore.selectedNotification.author.name ?? 'RefReps OES' }}</p>
                                                    <!-- <span class="isolate inline-flex rounded-md shadow-sm my-2"> -->
                                                    <!-- <button @click="makeDecision('advance')" type="button" class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">Advance</button>
                                                        <button @click="showRejectMessage = true" type="button" class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">Reject and Reset Module</button>
                                                        <button @click="makeDecision('approve')" type="button" class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">Allow</button> -->
                                                    <!-- </span> -->
                                                    <div v-if="mainStore.selectedNotification.type == 'quiz_attempt' && !mainStore.selectedNotification.hasResponded">
                                                        <fieldset class="mt-6 bg-gray-100 p-4 rounded-lg">
                                                            <div class="space-y-2">
                                                                <div class="relative flex items-start">
                                                                    <div class="flex h-6 items-center">
                                                                        <input id="approve" name="decision" type="radio" @input="setDecision('approve')" class="h-4 w-4 border-gray-300 text-black focus:ring-gray-100">
                                                                    </div>
                                                                    <div class="ml-3 text-sm leading-6">
                                                                        <label for="approve" class="font-medium text-gray-900">Approve</label>
                                                                        <p id="small-description" class="text-gray-500">Allow the learner to retake the quiz.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="relative flex items-start">
                                                                    <div class="flex h-6 items-center">
                                                                        <input id="reject" name="decision" type="radio" @input="setDecision('reject')" class="h-4 w-4 border-gray-300 text-black focus:ring-gray-100">
                                                                    </div>
                                                                    <div class="ml-3 text-sm leading-6">
                                                                        <label for="reject" class="font-medium text-gray-900">Reject</label>
                                                                        <p id="medium-description" class="text-gray-500">Do not allow the learner to retake the quiz.</p>
                                                                        <div v-if="showRejectMessage">
                                                                        <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 my-2">
                                                                            <div class="px-4 py-2 bg-white rounded-lg dark:bg-gray-800">
                                                                                <label for="comment" class="sr-only">Why are you rejecting this request?</label>
                                                                                <textarea id="comment" rows="3" v-model="rejectMessage" class="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Explain the reason for the rejection..." required></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>

                                                    <!-- <p v-if="showRejectMessage" class="ms-auto text-xs text-gray-500 dark:text-gray-400">Once you reject this attempt, the learner will have to resubmit if you allow a retake in the future.</p> -->

                                                    <p v-if="mainStore.selectedNotification.hasResponded" tabindex="0" class="focus:outline-none text-[15px] font-bold leading-4 text-black my-2">You have already submitted a response.</p>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="w-full flex justify-end items-center px-6 mt-2">

                                        <button v-if="!mainStore.selectedNotification.hasResponded && !showConfirmDecision && mainStore.selectedNotification.showActionButton" @click="makeDecision()" type="button" class="rounded-lg bg-black px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700">Confirm</button>
                                        <div v-if="showConfirmDecision">
                                            <h2 class="font-bold text-[14px] text-red-500">Confirm Reset</h2>
                                            <p class="text-[13px] text-red-500 text-left"> I understand that by selecting this option, I am resetting the learners module by making them rewatch all videos and wiping out all quiz scores.</p>
                                            <div class="flex items-center justify-end">
                                                <button @click="makeDecision()" type="button" class="rounded-lg mt-4 bg-black px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700">Confirm and Reset</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </Transition>

                            <iframe v-if="showHelp" src="https://support.refreps.com/en/support/solutions/articles/151000167120-how-to-view-notifications" class="w-full h-[80vh]" frameborder="0"></iframe>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapStores
} from "pinia";

import useMainStore from "@/stores/main";

export default {
    name: "NotificationsInbox",
    data() {
        return {
            counter: 0,
            timer: null,
            startX: 0,
            swipedNotification: null,
            swipeDistance: 0,
            isDragging: false,
            currentX: 0,
            showHelp: false,
            rejectMessage: '',
            showRejectMessage: false,
            didAcknowledge: false,
            decision: '',
            showConfirmDecision: false,
        }
    },
    computed: {
        ...mapStores(useMainStore),
        notifications() {
            if (this.mainStore.currentNotificationFilter == "all") return this.mainStore.notifications;
            if (this.mainStore.currentNotificationFilter == "unread") return this.mainStore.notifications.filter(n => n.views.length == 0);
            if (this.mainStore.currentNotificationFilter == "refreps") return this.mainStore.notifications.filter(n => n.type == "broadcast");
        },
    },
    methods: {
        setDecision(decision) {
            this.decision = decision
            this.showConfirmDecision = false
            if (decision == 'reject') {
                this.showRejectMessage = true
            } else {
                this.showRejectMessage = false
            }
        },
        selectNotification(notification) {
            notification.showActionButton = notification.type == 'quiz_attempt' ? true : false;
            this.mainStore.selectedNotification = notification
            console.log(notification)
            this.mainStore.notificationView = 'detail'
            this.markAsRead(notification)
        },
        async markAsRead(notification) {

            if (notification.views.map(v => v.id).includes(this.mainStore.user.id)) return;

            await this.axios.post(`${import.meta.env.VITE_BASE_LINK}/mark-notification-as-read`, {
                    id: notification.id,
                    user_id: this.mainStore.user.id,
                })
                .then(response => {
                    if (response.data.code == 200) {
                        this.mainStore.notifications.forEach(n => {
                            if (n.id == notification.id) {
                                n.views.push({
                                    id: this.mainStore.user.id,
                                    timestamp: Math.floor(Date.now() / 1000)
                                })
                            }
                        })

                        // this.mainStore.unreadMessages = this.mainStore.unreadMessages - 1;

                    } else {
                        alert('Something went wrong.')
                    }
                })
                .catch((error) => {
                    alert(error)
                    console.log(error);
                    this.isLoading = false
                });
        },
        async deleteNotification(notification) {

            await this.axios.post(`${import.meta.env.VITE_BASE_LINK}/delete-user-message`, {
                    id: notification.id,
                    user_id: this.mainStore.user.id,
                    type: 'student'
                })
                .then(response => {
                    this.mainStore.notifications = this.sortByTimestamp(response.data.notifications);
                })
                .catch(function (error) {
                    alert(error)
                    console.log(error);

                });
        },
        timeSince(date) {
            var seconds = Math.floor(Date.now() / 1000) - date;
            var interval = seconds / 31536000;
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + "d";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + "h";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + "m";
            }
            return Math.floor(seconds) != 0 ? Math.floor(seconds) + "s" : "Just Now";
        },
        checkCounter(notification, status) {
            if (status) {
                this.timer = setInterval(() => {
                    this.counter += 100
                    console.log(this.counter)
                    this.checkTimer(notification)
                }, 100)

            } else {
                clearInterval(this.timer)
                this.counter = 0
            }
        },
        checkTimer(notification) {
            if (this.counter > 300) {
                clearInterval(this.timer)
                this.counter = 0
                notification.promptDelete = true
            }
        },
        sortByTimestamp(arr) {
            return arr.sort((a, b) => {
                return b.timestamp - a.timestamp;
            });
        },
        async markAllRead() {
            await this.axios.post(`${import.meta.env.VITE_BASE_LINK}/mark-all-read`, {
                    ids: this.mainStore.notifications.map(n => n.id),
                    user_id: this.mainStore.user.id,
                })
                .then(response => {
                    this.mainStore.notifications.forEach(n => {
                        if (n.views.length == 0) {
                            n.views.push({
                                id: this.mainStore.user.id,
                                timestamp: Math.floor(Date.now() / 1000)
                            })
                        }
                    })
                })
                .catch(function (error) {
                    alert(error)
                    console.log(error);

                });
        },
        async makeDecision() {

            if (this.rejectMessage == '' && this.decision == 'rejectAdvance') return alert('Please provide a reason for rejecting this attempt.')

            await this.axios.post(`${import.meta.env.VITE_BASE_LINK}/make-decision-on-attempt`, {
                    course_id: this.mainStore.selectedNotification.metadata.course_id,
                    user_id: this.mainStore.selectedNotification.metadata.user_id,
                    module_id: this.mainStore.selectedNotification.metadata.module_id,
                    module_title: this.mainStore.selectedNotification.metadata.module_title,
                    decision: this.decision,
                    notification_id: this.mainStore.selectedNotification.id,
                    instructor: this.mainStore.user.name,
                    rejectMessage: this.rejectMessage
                })
                .then(response => {
                    if (response.data.code == 200) {
                        this.mainStore.selectedNotification.hasResponded = true;
                        this.showRejectMessage = false;
                        this.showConfirmDecision = false;
                    } else {
                        this.mainStore.selectedNotification.hasResponded = false;
                    }
                })
                .catch(function (error) {
                    alert(error)
                    console.log(error);

                });
        },

    },
    mounted() {

    },
    unmounted() {
        this.showHelp = false
        this.mainStore.notificationView = 'list'
    },
}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4dcac6;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1ec6c0;
}

:root {
    --animate-duration: 0.2s;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}
</style>
