<template>
<!-- <AppHeader :class="!mainStore.isOnline ? 'blur-sm' : ''" v-if="!mainStore.isWatchingVideo" /> -->
<router-view :class="!mainStore.isOnline ? 'blur-sm' : ''" v-slot="{ Component, route }">

    <Transition name="slide-fade" mode="out-in">
        <div :key="route.name">
            <component :is="Component" />
        </div>
    </Transition>

</router-view>
<!-- Global notification live region, render this permanently at the end of the document -->
<div v-if="mainStore.showTicketConfirmation" aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 animate__animated animate__fadeInRight mt-[6rem] z-[99999]">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">

        <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-gray-900">Ticket Submitted</p>
                        <p class="mt-1 text-sm text-gray-500">{{ `Support ticket #${mainStore.ticketNumber} successfully submitted.` }}</p>
                    </div>
                    <div class="ml-4 flex flex-shrink-0">
                        <button @click="mainStore.showTicketConfirmation = false, mainStore.ticketNumber = ''" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close</span>
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<LoadingContentOverlay v-if="mainStore.isLoadingInstructor || mainStore.isLoadingCourse || mainStore.isLoadingSurvey || mainStore.isSubmittingSurvey || mainStore.isLoadingDragonFlyRegistration || mainStore.isRegistering || mainStore.isSigningOut || mainStore.isLoadingDashboard || mainStore.isLoadingOnlineCourses" />

<div class="relative" style="z-index: 99999" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="mainStore.showZipCodeModal">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 max-w-md sm:p-6">
                <div>
                    <div class="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-gray-200">
                        <font-awesome-icon icon="fa-regular fa-bell-on" class="w-10 h-10" />
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                            Zip Code Required
                        </h3>
                        <div class="mt-2">
                            <p class="text-md text-gray-500">
                                Your zip code is requested solely to identify your local and/or state
                                association for high school activities.<br />
                                We respect your privacy and this information will not be used for any
                                other purposes.
                            </p>
                        </div>

                        <div class="text-left mt-3 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-black">
                            <label for="name" class="block text-xs font-medium text-gray-900">Zip Code</label>
                            <input type="text" name="zip" id="zip" class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Example: 01234" v-mask="'#####'" v-model="zipCode" @input="getZipDetails($event.target.value)" />
                        </div>
                        <transition name="slide-fade">
                            <p v-if="showZipError" class="text-red-600 mt-2">
                                {{ zipError }}
                            </p>
                        </transition>
                    </div>
                </div>

                <div class="mt-5 sm:mt-6">
                    <button type="button" @click="updateZipCode()" class="inline-flex w-full justify-center rounded-md bg-black px-3 py-4 text-md font-semibold text-white shadow-sm hover:bg-gray-800">
                        Update
                        <font-awesome-icon v-if="isUpdatingZipCode" icon="fa-duotone fa-spinner-third" class="w-6 h-6 inline-flex ml-2" spin />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Global notification live region, render this permanently at the end of the document -->
<div v-if="mainStore.showExamNotification" aria-live="assertive" class="animate__animated animate__slideInRight pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6" style="z-index: 9999;">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">

        <div class="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0 pt-0.5">
                        <img class="h-10 w-10 rounded-full" src="https://host.refreps.com/images/ohsaa.webp" alt="">
                    </div>
                    <div class="ml-3 w-0 flex-1">
                        <p class="text-sm font-medium text-gray-900">Exam Unlocked</p>
                        <p class="mt-1 text-sm text-gray-500">Your OHSAA Exam has been unlocked.</p>
                        <div class="mt-4 flex">
                            <button @click="goToExam()" type="button" class="inline-flex items-center rounded-md bg-black px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-800">Go to Exam</button>
                            <button @click="mainStore.showExamNotification = false" type="button" class="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Dismiss</button>
                        </div>
                    </div>
                    <div class="ml-4 flex flex-shrink-0">
                        <button @click="mainStore.showExamNotification = false" type="button" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Close</span>
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div v-if="!mainStore.isOnline" class="animate__animated animate__fadeIn fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[99999] overflow-hidden bg-white bg-opacity-90 flex flex-col items-center justify-center">
    <div v-if="!mainStore.isOnline" class="animate__animated animate__fadeIn grid place-items-center">
        <font-awesome-icon icon="fa-solid fa-wifi-slash" class="w-8 h-8" />
        <p class="text-lg">You are offline.</p>
        <button @click="checkOnlineStatus()" class="mt-8 mx-auto block relative overflow-hidden rounded-full border border-gray-200 py-1.5 px-4 text-md leading-6">
            <span class="text-gray-600 font-semibold">Refresh</span>
        </button>
    </div>
</div>

<div v-if="mainStore.showRuleBookOverlay" class="animate__animated animate__fadeIn fixed top-0 left-0 right-0 bottom-0 w-full h-[100vh] z-[99999] overflow-hidden bg-white">
    <div v-if="mainStore.showCover" id="bg-image"></div>
    <NFHSRulebook />
</div>

<SubmitTicketModal v-if="mainStore.showSubmitTicketModal" />

<NewChatMessageNotification v-if="mainStore.showNewChatMessage" />

<div v-if="mainStore.screenShots.length != 0" class="fixed bottom-4 left-4 w-full z-[99999]">

    <div v-for="screenShot in mainStore.screenShots" class="relative h-32 max-w-[200px] inline-flex mx-1.5 animate__animated animate__faster animate__slideInUp">
        <button @click.prevent="deleteImage(screenShot)" class="absolute -top-2 -right-2 text-red-500 z-[9999]">
            <font-awesome-icon icon="fa-solid fa-circle-minus" class="h-6 w-6" />
        </button>
        <img :src="screenShot.img" draggable @dragstart="startDrag($event, screenShot)" class="shadow-2xl min-w-[200px] max-w-[200px] object-fit" />
    </div>
</div>

<!-- <button v-tooltip="{ content: `Report a Bug`, html: true }" v-if="showBugButton" @click="mainStore.showSubmitTicketModal = true" type="button" class="fixed bottom-10 right-10 w-16 h-16 z-[99999] rounded-full bg-black hover:bg-zinc-800 text-white shadow-xl animate__animated animate__faster animate__slideInUp">
    <font-awesome-icon icon="fa-regular fa-bug" class="w-6 h-6 mx-auto" />
</button> -->

<div v-if="mainStore.showSupportButton && mainStore.isLoggedIn" data-dial-init class="fixed right-10 bottom-10 group z-[99999]" @mouseover="mainStore.isHovering = true, mainStore.showNewFeatureBackDrop = false" @mouseleave="mainStore.isHovering = false">
    <div v-if="mainStore.isHovering" id="speed-dial-menu-default" class="flex flex-col items-center mb-4 space-y-2">
        <a href="https://support.refreps.com" target="_blank" v-tooltip="{ content: `Visit Help Center`, html: true, placement: 'left' }" type="button" data-tooltip-target="tooltip-share" data-tooltip-placement="left" style="--animate-duration: 0.1s" class="animate__animated animate__fadeInUp flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
            <font-awesome-icon icon="fa-regular fa-memo-circle-info" class="text-black w-6 h-6" />
        </a>

        <button v-tooltip="{ content: `Submit Support Ticket`, html: true, placement: 'left' }" @click="mainStore.showSubmitTicketModal = true" type="button" data-tooltip-target="tooltip-share" data-tooltip-placement="left" style="--animate-duration: 0.2s" class="animate__animated animate__fadeInUp flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
            <font-awesome-icon icon="fa-regular fa-ticket" class="text-black w-6 h-6" />
        </button>

        <button v-tooltip="{ content: `Take Screen Shot`, html: true, placement: 'left' }" @click="capture()" type="button" data-tooltip-target="tooltip-print" data-tooltip-placement="left" style="--animate-duration: 0.3s" class="animate__animated animate__fadeInUp flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
            <font-awesome-icon icon="fa-duotone fa-camera-viewfinder" class="text-black w-6 h-6" />
        </button>
    </div>
    <button type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default" aria-expanded="false" class="shadow-xl flex items-center justify-center text-white bg-black rounded-full w-12 h-12 hover:bg-gray-800">
        <font-awesome-icon icon="fa-solid fa-question" class="w-6 h-6 " />
    </button>
</div>

<div v-if="isGeneratingScreenShot" class="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-[99999] overflow-hidden bg-white bg-opacity-90 flex flex-col items-center justify-center">
    <div class="grid place-items-center">
        <div aria-label="Loading..." role="status" class="text-center">
            <font-awesome-icon icon="fa-duotone fa-spinner-third" class="w-12 h-12" spin />
        </div>
    </div>
</div>

<div v-if="mainStore.didSaveSettings" class="fixed bottom-0 z-[99999] w-full overflow-hidden flex flex-col items-center justify-center shadow-lg mx-auto animate__animated animate__slideInUp">
    <div id="toast-simple" class="flex items-center w-full max-w-xs mb-12 p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
        <font-awesome-icon icon="fa-solid fa-check" />
        <div class="pl-4 text-sm font-normal">Settings saved successfully.</div>
    </div>
</div>

<div v-if="mainStore.isSyncingData" class="fixed bottom-0 z-[99999] w-full overflow-hidden flex flex-col items-center justify-center shadow-lg mx-auto animate__animated animate__slideInUp">
    <div id="toast-simple" class="flex items-center w-full max-w-xs mb-12 p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
        <font-awesome-icon icon="fa-duotone fa-spinner-third" spin />
        <div class="pl-4 text-sm font-normal">Syncing Data...</div>
    </div>
</div>

<div v-if="mainStore.showCopied" class="fixed bottom-0 z-[99999] w-full overflow-hidden flex flex-col items-center justify-center mx-auto animate__animated animate__slideInUp shadow-xl">
    <div id="toast-simple" class="flex items-center w-full max-w-xs mb-12 p-4 space-x-4 text-white bg-gray-800 divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
        <font-awesome-icon icon="fa-regular fa-clipboard-check" class="w-6 h-6" />
        <div class="pl-4 text-sm font-normal">{{ mainStore.emailCopied }} copied to clipboard.</div>
    </div>
</div>

<div v-if="mainStore.showRulebookSaved" class="fixed bottom-0 z-[99999] w-full overflow-hidden flex flex-col items-center justify-center mx-auto animate__animated animate__slideInUp shadow-xl">
    <div id="toast-simple" class="flex items-center w-full max-w-xs mb-12 p-4 space-x-4 text-black bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
        <font-awesome-icon icon="fa-regular fa-circle-check" class="w-6 h-6" />
        <div class="pl-4 text-sm font-normal">{{ mainStore.currentRule.title }} updated successfully.</div>
    </div>
</div>

<!-- <div v-if="mainStore.gettingMoreStudents" class="fixed bottom-0 z-[99999] w-full overflow-hidden flex flex-col items-center justify-center mx-auto animate__animated animate__slideInUp shadow-xl">
    <div id="toast-simple" class="flex items-center w-full max-w-xs mb-12 p-4 space-x-4 text-white bg-gray-800 divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
        <font-awesome-icon icon="fa-regular fa-users" class="w-6 h-6" />
        <div class="pl-4 text-lg font-normal">Getting more learners <font-awesome-icon icon="fa-duotone fa-spinner-third" class="ml-2 w-6 h-6" spin /></div>
    </div>
</div> -->

<div v-if="mainStore.showAddSeats" class="animate__animated animate__fadeIn animate__faster py-12 fixed bg-gray-700 bg-opacity-30 h-[100vh] w-[100vw] transition duration-150 ease-in-out z-[99999] top-0 right-0 bottom-0 left-0" id="modal">
    <div class="flex items-center justify-center h-[calc(100vh-50px)]">
        <div role="alert" class="animate__animated animate__fadeInDown container mx-auto w-11/12 md:w-2/3 max-w-xl flex justify-center" style="--animate-duration: 0.3s;">
            <div class="flex items-center justify-center py-8 px-4">
                <div class="relative max-w-xl rounded shadow-lg p-8 dark:bg-gray-800 bg-white">
                    <h1 class="text-[20px] font-bold leading-none text-gray-800 pt-6 text-center">{{ mainStore.organization.seats == 0 ? 'You have no seats remaining.' : `You have ${mainStore.organization.seats} seats remaining.` }}</h1>
                    <p class="text-lg leading-5 pt-6 text-center text-gray-500 mb-4">You have used all your seats to purchase more, select the number of seats you need or request an invoice below.</p>
                    <div class="w-full">
                        <label for="seats" class="block text-sm font-medium text-gray-700">Seats</label>
                        <select v-model="seats" id="seats" name="seats" class="w-full mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-black focus:outline-none focus:ring-black sm:text-sm">
                            <option :value="number" v-for="number in Array.from(Array(501).keys())">{{ number }}</option>
                        </select>
                    </div>
                    <div class="w-full">

                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse z-[99999]">
                            <button :class="seats == 0 ? 'opacity-10' : 'opacity-100'" :disabled="seats == 0" v-tooltip="{content: seats != 0 ? '' : 'You must select at least one seat'}" @click="purchaseSeats" type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-800 sm:ml-3 sm:w-auto sm:text-sm">Purchase Seats {{ seats != 0 ? `$(${(seats * 50).toFixed(0)})` : '' }}</button>
                            <button @click="mainStore.showAddSeats = false, seats = 0" type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm">Cancel</button>
                        </div>
                        <a href="https://webforms.pipedrive.com/f/1AEpMmVmgarXeTKGQWqHTd9BWRvg2BCgmeecMYFHTjEoo5KVEGBBCdgXlk7w49KZZ" class="float-right w-full underline text-blue-500 mt-3 text-right">Need an invoice?</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div v-if="mainStore.addNewRuleContent" class="animate__animated animate__fadeIn animate__faster py-12 fixed bg-gray-700 bg-opacity-30 h-[100vh] w-[100vw] transition duration-150 ease-in-out z-[999] top-0 right-0 bottom-0 left-0" id="modal">
    <div class="flex items-center justify-center h-[calc(100vh-50px)]">
        <div role="alert" class="animate__animated animate__fadeInDown container mx-auto w-11/12 md:w-2/3 max-w-3xl flex justify-center" style="--animate-duration: 0.3s;">
            <div class="flex items-center justify-center py-8 px-4">
                <div class="relative max-w-xl rounded shadow-lg p-8 dark:bg-gray-800 bg-white">
                    <h1 class="text-[20px] font-bold leading-none text-gray-800 pt-6 text-center">{{ mainStore.currentRule.title }}</h1>
                    <p class="text-lg leading-5 pt-6 text-center text-gray-500 mb-4"></p>
                    <div class="w-full min-w-96">
                        <label for="sectionName" class="block text-sm font-medium text-gray-700 mb-2">Give a title to the new content</label>
                        <input type="text" v-model="mainStore.sectionName" name="sectionName" placeholder="Section 1, etc..." class="w-full flex px-3 py-2 md:px-4 md:py-3 border-2 border-solid border-black rounded-lg font-medium placeholder:font-normal" />
                    </div>
                    <div class="w-full">

                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse z-[99999]">
                            <button @click="addSection()" type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-800 sm:ml-3 sm:w-auto sm:text-sm">Add</button>
                            <button @click="mainStore.addNewRuleContent = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm">Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<NotificationsSlideOver v-show="mainStore.showNotifications" />
<InstructorNotificationsSlideOver v-show="mainStore.showInstructorNotifications" />
<NewMessageModal v-if="mainStore.showNewMessage" />
<AssignCourseSlideOver v-if="mainStore.showAssignCourse" />
<HelpSlideOver v-if="mainStore.showHelp" />
<MultiAssignSlideOver v-if="mainStore.showMultiAssign" />
<NoRefundModal v-if="mainStore.showNoRefundModal" />

<CertificateOfCompletion v-show="mainStore.showCertificate && mainStore.currentCourse != {} && mainStore.currentUserCourse != {}" />

<div v-if="mainStore.showViewer" style="z-index: 9999;" class="py-12 transition duration-150 ease-in-out h-[100vh] bg-zinc-800 bg-opacity-[0.90] top-0 right-0 bottom-0 left-0 fixed">
    <div class="flex items-center justify-center">

        <div class="text-center text-white flex items-center justify-center">
            <div class="h-[85vh]">
                <div class="flex justify-end">
                    <button @click="mainStore.showViewer = false">
                        <font-awesome-icon icon="fa-solid fa-xmark" class="h-10 w-10 text-white" /></button>
                </div>
                <vue-pdf-app class="w-[80vw] h-[85vh]" style="z-index: 9999;" :pdf="mainStore.url"></vue-pdf-app>
            </div>
        </div>

    </div>
</div>

<div class="relative overflow-x-hidden" style="z-index: 99999" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="mainStore.showExam">
    <div class="fixed inset-0 transition-opacity overflow-x-hidden"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto overflow-x-hidden">
        <div v-if="mainStore.showExam" class="bg-gray-200">
            <div class="flex items-center px-6 py-4 mx-auto overflow-x-auto whitespace-nowrap">
                <a @click.prevent="closeExam()" href="#" class="text-gray-600 dark:text-gray-200">
                    Exit Exam
                </a>

            </div>
        </div>
        <!-- <iframe v-show="!mainStore.isLoadingCourse" :src="`https://exam.refreps.com/${mainStore.currentExam?.exam_id}`" @load="mainStore.isLoadingCourse = false" class="bg-white w-full h-full overflow-x-hidden translate duration-500 ease-in-out" :class="mainStore.showExam ? 'scale-100' : 'scale-0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
    </div>
</div>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";
import AppHeader from "@/components/AppHeader.vue";
import {
    uuid
} from "vue-uuid";
import {
    io
} from "socket.io-client";
import {
    mask
} from "vue-the-mask";
import {
    VOffline
} from 'v-offline';
import html2canvas from 'html2canvas'

import LoadingContentOverlay from "@/components/Overlays/LoadingContentOverlay.vue";
import SubmitTicketModal from "@/components/Modals/Support/SubmitTicketModal.vue";
import NotificationsSlideOver from "@/components/NotificationsUI/NotificationsSlideOver.vue";
import InstructorNotificationsSlideOver from "@/components/NotificationsUI/InstructorNotificationsSlideOver.vue";
import NewMessageModal from "@/components/NotificationsUI/NewMessageModal.vue";
import NewChatMessageNotification from "@/components/Instructor/MessagingUI/NewChatMessageNotification.vue";
import AssignCourseSlideOver from "@/components/Instructor/AssignCourseSlideOver.vue";
import HelpSlideOver from "@/components/Instructor/HelpSlideOver.vue";
import MultiAssignSlideOver from "@/components/Instructor/MultiAssignSlideOver.vue";
import NFHSRulebook from "@/components/Student/Course/NFHSRulebook.vue";
import NoRefundModal from "@/components/Modals/Checkout/NoRefundModal.vue";
import VuePdfApp from "vue3-pdf-app";
import CertificateOfCompletion from "@/components/Certificates/CertificateOfCompletion.vue";
import LoginStart from '@/components/Login/LoginStart.vue'

export default {
    components: {
        AppHeader,
        VOffline,
        LoadingContentOverlay,
        SubmitTicketModal,
        NotificationsSlideOver,
        InstructorNotificationsSlideOver,
        NewMessageModal,
        NewChatMessageNotification,
        AssignCourseSlideOver,
        HelpSlideOver,
        MultiAssignSlideOver,
        NFHSRulebook,
        NoRefundModal,
        VuePdfApp,
        CertificateOfCompletion,
        LoginStart
    },
    computed: {
        ...mapStores(useMainStore),
    },
    directives: {
        mask,
    },
    data() {
        return {
            zipCode: "",
            state: "",
            showZipError: false,
            isUpdatingZipCode: false,
            zipError: "",
            onLine: true,
            showOnlineStatus: false,
            showBugButton: false,
            hovering: false,
            screenShots: [],
            isGeneratingScreenShot: false,
            timeoutID: null,
            seats: 0,
        };
    },
    async mounted() {

        if (!this.mainStore.isLoggedIn) {
            this.$router.push({
                name: 'login',
            });
            return
        }

        if (this.mainStore.isUserInstructor && this.getUserById(this.mainStore.organization?.global_settings?.instructorSettings, this.mainStore.user?.id)?.enabled) {

            // await this.$router.push({
            //     name: 'organization',
            //     params: {
            //         id: this.mainStore.organization.companyID
            //     },
            // });
            this.mainStore.isLoadingInstructor = false;

            return
        } else {
            this.mainStore.isLoadingInstructor = false;
        }

        this.emitter.on('signOut', (event) => {
            this.mainStore.userNotifications = []
        });

        this.emitter.on('refreshUserNotifications', (event) => {
            this.getUserMessages()
        });

        this.emitter.on('downloadCert', (event) => {
            this.download(event.data)
        });

        this.mainStore.user_course.name = ''

        setTimeout(() => {
            this.showBugButton = true
        }, 800);

        if (this.mainStore.isLoggedIn) {
            this.getUserMessages();
       
            if (
                this.mainStore.user.zipCode == 0 ||
                !this.mainStore.user.zipCode ||
                this.mainStore.user.state == "" ||
                !this.mainStore.user.state
            ) {
                this.mainStore.showZipCodeModal = true;
                setTimeout(() => {
                    this.mainStore.showZipCodeModal2 = true;
                }, 1000);

            }

        }

        window.addEventListener("contextmenu", (event) => {
            event.preventDefault();
        });

        this.getShoppingCartItems()
    },
    methods: {
        ...mapActions(useMainStore, ["getOrganizationInactiveVideoIds"]),
        updateUserStatus(userId, status) {
            this.mainStore.students.forEach((student) => {
                if (student.id === userId) {
                    user.status = status;
                }
            });
        },
        closeExam(exam) {
            this.mainStore.isSidebarOpen = true
            this.mainStore.currentExam = null
            this.mainStore.showExam = false
        },
        getUserById(users, id) {
            console.log(users)
            return users.find(user => user.id === id) || null;
        },
        handleGlobalClick(event) {
            if (event.target.tagName === 'BUTTON' || event.target.tagName === 'A') {
                console.log('Button clicked:', event.target);
                // You can log more details or send it to a logging service if needed
            }
        },
        async download(course) {

            this.mainStore.currentUserCourse = course;
            this.mainStore.currentCourse = course;
            this.mainStore.showCertificate = true;
            this.isDownloading = true
            await this.$nextTick();
            html2canvas(this.$refs.certificate, {
                    logging: true,
                    letterRendering: true,
                    allowTaint: true,
                    useCORS: true,
                    scale: 2,
                    backgroundColor: null,

                })
                .then((canvas) => {
                    window.saveAs(
                        canvas.toDataURL("image/jpg"),
                        `${course.name}-certificate.jpg`
                    );
                    this.mainStore.showCertificate = false;
                    this.isDownloading = false
                    this.mainStore.currentCertDownloading = ''
                })
                .catch((error) => {
                    console.log("ERROR SAVING FESTIVAL IMAGE", error);
                });
        },
        goToExam() {
            this.mainStore.showExamNotification = false;
            window.open(this.mainStore.exam_url, '_blank');
        },
        checkOnlineStatus() {
            if (navigator.onLine) {
                this.mainStore.isOnline = true;
            } else {
                this.mainStore.isOnline = false;
            }
        },
        timeSince(date) {
            var seconds = Math.floor(Date.now() / 1000) - date;
            var interval = seconds / 31536000;
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + "d";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + "h";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + "m";
            }
            return Math.floor(seconds) != 0 ? Math.floor(seconds) + "s" : "Just Now";
        },
        randomInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        getUserMessages() {
            this.mainStore.isGettingMessages = true;
            this.axios
                .post(`${import.meta.env.VITE_BASE_LINK}/get-user-notifications`, {
                    id: this.mainStore.user.id,
                    type: "student",
                    organizationIDs: this.mainStore.user.organizations.map((org) => org.companyID),
                })
                .then((response) => {
                    this.mainStore.userNotifications = this.sortByTimestamp(response.data.notifications);

                    this.mainStore.userNotifications.forEach(notification => {
                        if (this.isTimestampFromToday(notification.timestamp)) {
                            notification.category = 'today'
                        } else if (this.isTimestampFromCurrentMonth(notification.timestamp)) {
                            notification.category = 'month'
                        } else {
                            notification.category = 'earlier'
                        }
                    });

                    if (response.data.notifications.length != 0) {
                        this.mainStore.currentMessage = response.data.notifications[0];
                        this.mainStore.userNotifications[0].isActive = true;
                    }
                    this.mainStore.userNotifications.forEach(local_notification => {
                        local_notification.promptDelete = false
                    })
                    this.mainStore.isGettingMessages = false;

                    this.mainStore.userNotifications.forEach((notification) => {
                        if (!this.mainStore.user.readNotifications.map(n => n.id).includes(notification.id)) {
                            this.mainStore.unreadNotifications++;
                        }
                    })

                    this.mainStore.userNotifications = this.sortNotifications(this.mainStore.userNotifications, this.mainStore.user.readNotifications.map(n => n.id));

                })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                    this.mainStore.isGettingMessages = false;
                });
        },
        sortNotifications(notifications, readNotifications) {
            return notifications.sort((a, b) => {
                // Check if notification is unread
                const aUnread = !readNotifications.includes(a.id);
                const bUnread = !readNotifications.includes(b.id);

                // If read status is different, prioritize unread
                if (aUnread !== bUnread) {
                    return bUnread - aUnread;
                }

                // If read status is the same, sort by timestamp (most recent first)
                return b.timestamp - a.timestamp;
            });
        },
        addSection() {

            this.axios
                .post(`${import.meta.env.VITE_BASE_LINK}/add-section`, {
                    rulebook_id: this.mainStore.currentRulebook.id,
                    rule_id: this.mainStore.currentRule.id,
                    sectionName: this.mainStore.sectionName,
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.mainStore.currentRule.sections.push(response.data.section)
                        this.mainStore.addNewRuleContent = false
                        this.mainStore.sectionName = ''
                        this.mainStore.showRulebookSaved = true
                        setTimeout(() => {
                            this.mainStore.showRulebookSaved = false
                        }, 1500);
                        return
                    }
                })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                    this.mainStore.isGettingMessages = false;
                });
        },
        isTimestampFromToday(timestamp) {
            // Convert timestamp from seconds to milliseconds
            const timestampInMilliseconds = timestamp * 1000;

            const inputDate = new Date(timestampInMilliseconds);
            const today = new Date();

            return inputDate.getDate() === today.getDate() &&
                inputDate.getMonth() === today.getMonth() &&
                inputDate.getFullYear() === today.getFullYear();
        },
        isTimestampFromCurrentMonth(timestampInSeconds) {
            // Convert timestamp from seconds to milliseconds
            const timestampInMilliseconds = timestampInSeconds * 1000;

            const inputDate = new Date(timestampInMilliseconds);
            const today = new Date();

            return inputDate.getMonth() === today.getMonth() &&
                inputDate.getFullYear() === today.getFullYear();
        },
        isFromPreviousMonthOrEarlier(timestamp) {
            // Convert the timestamp to milliseconds (since JavaScript works with milliseconds)
            const dateFromTimestamp = new Date(timestamp * 1000);

            // Get the current date and set it to the first of the month
            const currentDate = new Date();
            currentDate.setDate(1);
            currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero

            // Check if the dateFromTimestamp is before the currentDate (set to the first of the month)
            return dateFromTimestamp < currentDate;
        },
        updateZipCode() {
            if (this.zipCode.length != 5) {
                this.zipError = "You must enter a valid zip code.";
                this.showZipError = true;
                return;
            }
            this.isUpdatingZipCode = true;
            this.showZipError = false;

            this.axios
                .post(`${import.meta.env.VITE_BASE_LINK}/updateZipCodeAndState`, {
                    zipCode: this.zipCode,
                    state: this.state,
                    id: this.mainStore.user.id,
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.mainStore.user = response.data.user;
                        this.mainStore.showZipCodeModal = false;
                        this.isUpdatingZipCode = false;
                        return;
                    }
                    this.isUpdatingZipCode = false;
                    this.zipError = response.data.error;
                    this.showZipError = true;
                })
                .catch((error) => {
                    alert(error);
                    this.isUpdatingZipCode = true;
                    this.zipError = error;
                    this.showZipError = true;
                    console.log(error);
                });
        },
        getZipDetails(zipCode) {
            this.showZipError = false;
            if (zipCode.length == 5) {
                const url = `https://api.zippopotam.us/us/${zipCode}`;

                fetch(url)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        const state = data["places"][0]["state abbreviation"];
                        this.state = state;
                    })
                    .catch((error) => {
                        console.error("There has been a problem with your fetch operation:", error);
                        console.log(error);
                    });
            }
        },
        capture() {

            this.isGeneratingScreenShot = true
            html2canvas(document.body)
                .then(async canvas => {

                    var img = canvas.toDataURL({
                        format: 'png',
                        quality: 0.5
                    });
                    // var file = this.dataURLtoFile(img, 'temp.png');
                    this.mainStore.screenShots.push({
                        id: uuid.v4(),
                        img: img
                    });
                    // this.uploadFile(file, img)
                    this.isGeneratingScreenShot = false
                });
        },
        dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {
                type: mime
            });
        },
        deleteImage(screenShot) {
            this.mainStore.screenShots = this.mainStore.screenShots.filter((shot) => shot != screenShot);
        },
        startDrag(evt, file) {
            evt.dataTransfer.setData('id', file.id)
            evt.dataTransfer.setData('img', file.img)
        },
        sortByTimestamp(arr) {
            return arr.sort((a, b) => {
                return b.timestamp - a.timestamp;
            });
        },
        purchaseSeats() {

            var mode =
                import.meta.env.VITE_MODE
            this.axios.post(mode == 'production' ? `${import.meta.env.VITE_STRIPE_LINK}/create-seats-session` : `${import.meta.env.VITE_STRIPE_LINK}/create-seats-session-dev`, {
                    name: this.mainStore.user.name,
                    email: this.mainStore.user.email,
                    seats: this.seats,
                    companyID: this.$route.params.id,
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        window.location.href = response.data.url;
                    } else {
                        this.isLoading = false
                        alert("There was an error purchasing seats.");
                    }
                })
                .catch((error) => {
                    alert(error);
                    this.isLoading = false
                    console.log(error);
                });
        },
        getShoppingCartItems() {
            this.axios
                .get(`${import.meta.env.VITE_BASE_LINK}/getShoppingCarItems`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.mainStore.activeCartItems = response.data.items;
                    }
                });
        },
        

    },

};
</script>

<style>
#bg-image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
    opacity: 90%;
    overflow: hidden;
    background: url("https://host.refreps.com/images/bg.png");
    transform: rotate(-10deg);
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.pop-enter-active,
.pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
}

@media print {

    html,
    body {
        display: none;
        /* hide whole page */
    }
}

.html2canvas-container {
    width: 100vw !important;
    height: 100vh !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #797979;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

:root {
    --animate-duration: 0.2s;
}
</style>
