import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import VueAxios from 'vue-axios'
import useMainStore from '@/stores/main'
import App from './App.vue'
import router from './router'
import VeeValidationPlugin from './includes/validation'
import VuePlyr from 'vue-plyr'
import VueLazyLoad from 'vue3-lazyload'
import VueApexCharts from 'vue3-apexcharts'
import Vue2TouchEvents from 'vue2-touch-events'
import VueHtml2Canvas from 'vue-html2canvas'
import FloatingVue from 'floating-vue'
import Toast from 'vue-toastification'
import { VOffline } from 'v-offline'
import moment from 'moment'
import VueVideoPlayer from '@videojs-player/vue'
import LoadScript from 'vue-plugin-load-script'
import VueIframe from 'vue-iframes'
import mitt from 'mitt'

import '@fortawesome/fontawesome-pro/js/duotone'
import '@fortawesome/fontawesome-pro/js/regular'
import '@fortawesome/fontawesome-pro/js/solid'
import './assets/index.css'
import 'vue-plyr/dist/vue-plyr.css'
import 'floating-vue/dist/style.css'
import 'regenerator-runtime'
import 'vue-toastification/dist/index.css'
import { io } from 'socket.io-client'
import * as Sentry from "@sentry/vue";

import piniaPersist from 'pinia-plugin-persist'

const emitter = mitt()
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPersist)
pinia.use(({ store }) => {
  store.$router = markRaw(router)
  store.emitter = markRaw(emitter)
})

app.use(pinia)
app.use(router)
app.use(Toast)
app.use(FloatingVue)
app.use(VueVideoPlayer)
app.use(VueLazyLoad)
app.use(VueHtml2Canvas)
app.use(Vue2TouchEvents)
app.use(moment)
app.use(VueIframe)
app.use(VueApexCharts)
app.use(LoadScript)
app.use(VeeValidationPlugin)
app.use(VuePlyr, { plyr: {} })
app.use(VueAxios, axios)

app.component('apexchart', VueApexCharts)
app.component('VOffline', VOffline)
app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.emitter = emitter
axios.defaults.withCredentials = true

const LONG_PRESS_DEFAULT_DELAY = 750
const longPressEvent = new CustomEvent('long-press')

Sentry.init({
  app,
  dsn: "https://d3816147f3520479729436ebd3ec8a39@o1257122.ingest.us.sentry.io/4507866816118784",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.directive('long-press', {
  mounted(el, binding, vnode) {
    let longPressTimeout = null
    let elapsed = false

    const onPointerUp = () => {
      clearTimeout(longPressTimeout)
      document.removeEventListener('pointerup', onPointerUp)
    }

    const onPointerDown = () => {
      document.addEventListener('pointerup', onPointerUp)
      el.addEventListener('click', swallowClick, { once: true })

      longPressTimeout = setTimeout(() => {
        if (vnode.component) vnode.component.emit('long-press')
        else el.dispatchEvent(longPressEvent)
        elapsed = true
      }, binding.value || LONG_PRESS_DEFAULT_DELAY)

      elapsed = false
    }

    const swallowClick = (e) => {
      if (!elapsed) return true
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    el.addEventListener('pointerdown', onPointerDown)

    el._longPressCleanup = () => {
      clearTimeout(longPressTimeout)
      el.removeEventListener('pointerdown', onPointerDown)
    }
  },
  unmounted(el) {
    el._longPressCleanup()
  }
})

app.directive('track-click', {
  mounted(el, binding) {
    el.addEventListener('click', () => {
      const eventData = {
        eventType: 'click',
        element: el.id,
        additionalData: binding.value
      }

      fetch('https://yourbackend.com/api/trackEvent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eventData)
      })
    })
  }
})

// Import and initialize the main store
const mainStore = useMainStore()

// Optimized authentication function
async function authenticateUser() {
  try {
    // Step 1: Send a POST request to authenticate the user
    const { data } = await axios.post(`${import.meta.env.VITE_BASE_LINK}/authenticateUser`, {
      apiKey: 'EjuoZZcumh1SQwCX1LuiLDbpusDue2k64CtVXcgLjXFDTMxPvif1498TPHWXcGW9'
    })

    // Step 2: Check if the user is logged in
    if (data.isLoggedIn) {
      // Step 3: Handle logged-in user
      await handleLoggedInUser(data)
    } else {
      // Step 4: Handle non-logged-in user
      await Object.assign(mainStore, {
        isLoggedIn: false,
        user: null,
        userExams: [],
        organization: null,
        organizations: [],
        isUserInstructor: false,
        showTermsModal: false,
        isInstructor: false
      })
      await handleNonLoggedInUser(data)
    }
  } catch (error) {
    // Step 5: Log authentication error and handle as non-logged-in user
    console.error('Authentication error:', error)
    await handleNonLoggedInUser()
  }
}

async function handleLoggedInUser(data) {

  console.log(data)
  // Step 6: Update main store with user data
  await Object.assign(mainStore, {
    isLoggedIn: true,
    user: data.user,
    userExams: data.userExams,
    organization: data.organization,
    organizations: data.organizations,
    isUserInstructor: data.isUserInstructor,
    showTermsModal: data.showPolicyModal,
    isInstructor: data.isUserInstructor,
    activeCartItems: data.user.shoppingCart,
  })
  Sentry.setUser({
    id: data.user.id,
    email: data.user.email,
    username: data.user.username,
    isUserInstructor: data.isUserInstructor
  })
  setupSocket()
  // Step 7: Handle organization logic
  await handleOrganizationLogic()
  // Step 8: Mount app and handle routing
  await mountAppAndRoute(data.isUserInstructor)
}

async function handleOrganizationLogic() {
  // Step 9: Check if user has multiple organizations but no current organization set
  if (mainStore.organizations.length > 1 && !mainStore.user.currentOrganization) {
    // Step 10: Set the first organization as current
    mainStore.user.currentOrganization = mainStore.organizations[0]
    try {
      // Step 11: Update the current organization on the server
      await axios.post(`${import.meta.env.VITE_BASE_LINK}/updateCurrentOrganization`, {
        userId: mainStore.user.id,
        organization: mainStore.organization
      })
    } catch (error) {
      console.error('Error updating organization:', error)
    }
  } else if (mainStore.user.currentOrganization) {
    // Step 12: Find and set the current organization in the store
    mainStore.organization = mainStore.organizations.find(
      (org) => org.companyID === mainStore.user.currentOrganization
    )
  }
}

async function mountAppAndRoute(isUserInstructor) {
  // Step 13: Mount the app
  app.mount('#app')
  // Step 14: Wait for the router to be ready
  await router.isReady()
  const currentRoute = router.currentRoute.value

  if (mainStore.user.needsPasswordReset) {
    await router.push({ name: 'login', query: { method: 'forceReset', id: mainStore.user.id } })
    return
  }

  const isJoinId = window.location.pathname.includes('/join/')

  // Step 15: Check if the current route is a join link
  if (isJoinId) {
    const joinId = window.location.pathname.split('/').pop()
    console.log(joinId)
    await router.push({ name: 'invite', query: { id: joinId, isLoggedIn: 'true' } })
    return
  }

  // Step 15: Check if the current route is an invite
  if (currentRoute.path.includes('/invite')) {
    await router.push({ name: 'invite', query: { id: currentRoute.query.id, isLoggedIn: 'true' } })
  } else if (currentRoute.path.includes('/explore')) {
    await router.push({ name: 'explore' })
  } else if (isUserInstructor) {
    // Step 16: Set loading state for instructor
    mainStore.isLoadingInstructor = true
    // Step 17: Route to organization page
    await router.push({
      name: 'organization',
      params: {
        id: mainStore.user.currentOrganization?.companyID || mainStore.organization.companyID
      }
    })
  } else {
    // Step 18: Route to dashboard for non-instructors
    await router.push({ name: 'dashboard' })
  }
}

async function handleNonLoggedInUser(data) {
  app.mount('#app')

  await Object.assign(mainStore, {
    isLoggedIn: false,
    user: null,
    userExams: data.userExams,
    organization: data.organization,
    organizations: data.organizations,
    isUserInstructor: data.isUserInstructor,
    showTermsModal: data.showPolicyModal,
    isInstructor: data.isUserInstructor
  })

  const isInviteUrl = window.location.pathname.includes('/invite')
  const isJoinId = window.location.pathname.includes('/join/')
  const isDashboard = window.location.pathname.includes('/dashboard')
  const isExplore = window.location.pathname.includes('/explore')

  if (isExplore) {
    await router.push({ name: 'explore' })
    return
  }

  // Step 15: Check if the current route is a join link
  if (isJoinId) {
    const joinId = window.location.pathname.split('/').pop()
    console.log(joinId)
    await router.push({ name: 'invite', query: { id: joinId, isLoggedIn: 'false' } })
    return
  }

  if (isInviteUrl) {
    const inviteId = new URLSearchParams(window.location.search).get('id')
    await router.push({ name: 'invite', query: { id: inviteId, isLoggedIn: 'false' } })
    return
  }

  if (isDashboard) {
    await router.push({ name: 'dashboard' })
    return
  }
}

async function setupSocket() {
  // Initialize socket connection
  const connectSocket = () => {
    return new Promise((resolve, reject) => {
      mainStore.socket = io('wss://notifications.refreps.com', {
        withCredentials: true
      })

      mainStore.socket.on('connect', () => {
        console.log('Connected to socket server')
        mainStore.socket.emit('user_connect', {
          id: mainStore.user.id,
          orgIds: mainStore.user.organizations.map((org) => org.companyID)
        })

        setupSocketListeners()
        resolve()
      })

      mainStore.socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error)
        reject(error)
      })
    })
  }

  const setupSocketListeners = () => {
    mainStore.socket.on('user_joined', (data) => {
      if (data.orgId === mainStore.user.id) {
        console.log(data)
      }
    })
  
    mainStore.socket.on('user_left', (data) => {
      if (data.orgId === mainStore.user.id) {
        console.log(data)
      }
    })
  }

  try {
    await connectSocket()
  } catch (error) {
    console.error('Failed to connect to socket:', error)
  }

  
}

// Step 22: Start the authentication process
authenticateUser().catch((error) => console.error('Authentication process failed:', error))
